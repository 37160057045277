import * as echarts from 'echarts'
import store from '@/store'
// 饼图
export const optionIndexPie = chartdata => {

  //颜色16进制换算rgba,添加透明度
  function hexToRgba (hex, opacity) {
    return (
      'rgba(' +
      parseInt('0x' + hex.slice(1, 3)) +
      ',' +
      parseInt('0x' + hex.slice(3, 5)) +
      ',' +
      parseInt('0x' + hex.slice(5, 7)) +
      ',' +
      opacity +
      ')'
    );
  }

  let backgroundColor = 'rgba(0,0,0,0)';
  let radius = ['50%', '54%'];
  // 颜色系列
  let color = ['#37FFC9', '#FFE777', '#19D6FF', '#32A1FF', '#cccccc', '#ff1111'];
  let color1 = [],
    color2 = [],
    color3 = [];
  // 设置每层圆环颜色和添加间隔的透明色
  color.forEach((item) => {
    color1.push(item);
    color2.push(hexToRgba(item, 0.7));
    color3.push(hexToRgba(item, 0.4));
  });
  let data1 = [];
  let sum = 0;
  // 根据总值设置间隔值大小
  chartdata.forEach((item) => {
    sum += Number(item.value);
  });
  data1 = chartdata
  // if (chartdata.length < 2) {
  //   data1 = chartdata
  // } else {

  //   // 给每个数据后添加特定的透明的数据形成间隔
  //   chartdata.forEach((item, index) => {

  //     if (item.value !== 0) {

  //       data1.push(item, {
  //         name: '',
  //         value: sum / 70,
  //         labelLine: {
  //           show: false,
  //           lineStyle: {
  //             color: 'transparent',
  //           },
  //         },
  //         itemStyle: {
  //           color: 'transparent',
  //         },
  //       });
  //     }
  //   });
  // }
console.log(data1,'sssssss')
  // 每层圆环大小
  let radius2 = [Number(radius[1].split('%')[0]) + 0 + '%', Number(radius[1].split('%')[0]) + 4 + '%'];
  let radius3 = [Number(radius[1].split('%')[0]) + 4 + '%', Number(radius[1].split('%')[0]) + 8 + '%'];
  let option = {
    backgroundColor: backgroundColor,
    grid: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      containLabel: true,
    },
    tooltip: {
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: 5,
      show: (data) => {
        return data && data.name ? true : false
      },
      formatter: (params) => {
        return params.name !== '' ?
          params.name + ' : ' + params.value + '亩' + '\n' + '(' + params.percent + '%)' : null

      },
    },
    series: [
      // 最外层圆环
      {
        type: 'pie',
        radius: radius3,
        center: ['50%', '50%'],
        hoverAnimation: false,
        startAngle: 90,
        selectedMode: 'single',
        selectedOffset: 0,
        itemStyle: {
          normal: {
            color: (params) => {

              let i = params.dataIndex > 11 ? params.dataIndex % 12 : params.dataIndex
              console.log(color1[i],'color1[i]')
              return color1[i];
            },
          },
        },
        label: {
          show: true,
          position: 'outside',
          formatter: (params) => {

            let zzb = 0;
            data1.forEach((item, index) => {
              // 当前值一半加上前面的值是否大于50%（判断label朝向）
              if (index <= params.dataIndex && item.name != '') {
                if (index == params.dataIndex) {
                  zzb += Number(item.value) / 2;
                } else {
                  zzb += Number(item.value);
                }
              }
            });
            if (params.name != '') {
              console.log(params,'params')
              // 若当前值一半加上前面的值的占比大于0.5三角形朝右，相反朝左
              if (zzb / sum > 0.5) {
                return '{txt|' + params.name +': '+ params.percent + '%}' + '\n{san|▶}';
              } else {
                return '{txt|' + params.name +': '+ params.percent + '%}' + '\n{san|◀}';
              }
            }
          },
          align: 'left',
          padding: [0, -50, 10, -60],
          rich: {
            txt: {
              color: '#fff',
              width: 10,
              height: 10,
              padding: [-10, 10, 0, 24],
            },
            san: {
              color: store ?.state.themeInfo.baseColor || '#00C395',
              padding: [-30, -3, -20, 8],
            },
          },
        },
        labelLine: {
          show: true,
          length: 15,
          length2: 70,
          lineStyle: {
            color: '#fff',
            width: 2,
          },
        },
        data: data1,
        z: 666,
      },
      {
        type: 'pie',
        radius: radius2,
        center: ['50%', '50%'],
        hoverAnimation: false,
        startAngle: 90,
        selectedMode: 'single',
        selectedOffset: 0,
        itemStyle: {
          normal: {
            color: (params) => {
              let i = params.dataIndex > 11 ? params.dataIndex % 12 : params.dataIndex
              return color2[i];
            },
          },
        },
        label: {
          show: false,
          formatter: '{b}' + ' ' + '{c}',
        },
        data: data1,
        z: 666,
      },
      {
        type: 'pie',
        radius: radius,
        center: ['50%', '50%'],
        hoverAnimation: false,
        startAngle: 90,
        selectedMode: 'single',
        selectedOffset: 0,
        itemStyle: {
          normal: {
            color: (params) => {
              let i = params.dataIndex > 11 ? params.dataIndex % 12 : params.dataIndex

              return color3[i];
            },
          },
        },
        label: {
          show: false,
          formatter: '{b}' + ' ' + '{c}',
        },
        data: data1,
        z: 666,
      },
    ],
  };
  return option

}
// 含最大小值 折线图
export const optionMaxMinLine = (data) => {
  const baseColor = store ?.state.themeInfo.baseColor || '#00C395'
  let maxColor = data.maxVal <= data.alarmMax  ? baseColor : '#ff4d4f'
  let minColor = data.minVal >= data.alarmMin  ? baseColor : '#ff4d4f'
  let maxY = data.maxVal > data.alarmMax ?  data.maxVal : data.alarmMax
  let minY = data.minVal < data.alarmMin ? data.minVal : data.alarmMin
 
  let option = {

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: 5,
      formatter: function (arr) {
        let param = arr[0]
        return param.marker + " " + param.name + "<br>"
          + "&nbsp;&nbsp;&nbsp;&nbsp;" + param.seriesName + ":&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;" + param.value + data.paramUnit + "<br>";
      }

    },
    grid: {
      top: '26%',
      left: '3%',
      right: '7%',
      bottom: '18%'
    },
    xAxis: {
      //  type: 'category',
      data: data.xAxis,
      axisLabel: {
        show: true,
        textStyle: {
          color: '#999', //X轴文字颜色
          fontSize: 10
        },
        formatter: function (params) {
          var newParamsName = ''; // 最终拼接成的字符串
          var paramsNameNumber = params.length; // 实际标签的个数
          var provideNumber = params.indexOf(' '); // 每行能显示的字的个数
          var str1 = params.substring(0, provideNumber);
          var str2 = params.substring(provideNumber, paramsNameNumber);
          newParamsName = str1 + '\n' + str2;

          return newParamsName;
        }
      },
    },
    yAxis: {
      name: data.paramUnit,
      nameTextStyle: {
        color: baseColor,
        fontSize: 14,
      },
      max: Math.ceil(maxY) + 10,
      min:  Math.floor(minY) - 10,
      type: 'value',
      axisLabel: {
        formatter: params => {
          
          const returnValue = params > 10000 ? (params / 1000) + 'k' : params
          return returnValue
        }
      }
    },
    visualMap: [
     {
      show: false,
      seriesIndex: 0, //指定取哪个系列的数据，即哪个系列的 series.data。默认取所有系列。
      pieces: [
        {
          gt: data.alarmMin, //小于，less than
          lt: data.alarmMax, //大于，greater than
          // lte（小于等于 less than or equals），gte（大于等于，greater than or equals）
          color: baseColor,
          borderColor: baseColor
        }
      ],
      outOfRange: { // 在选中范围外的视觉元素。
        color: '#ff4d4f',
        borderColor: '#ff4d4f'
      }

     }

    ],
    series: [
      {
        name: data.paramName,
        type: 'line',
        // color: [store ?.state.themeInfo.baseColor || '#00C395'],
        markPoint: {
          data: [
            {
              type: 'max', name: '最大值', label: {
                formatter: '{c}' + data.paramUnit,
                color: "#fff"
                },itemStyle: {
                  color: maxColor
                }
            },
            {
              type: 'min', name: '最小值', label: {
                formatter: '{c}' + data.paramUnit,
                color: "#fff"
              },itemStyle: {
                color: minColor
              }
            }
          ]
        },
        markLine: {
          data: [
            {
              type: 'average', name: '平均值', label: {
                formatter: '{c}' + data.paramUnit,
              },
              lineStyle: {
                color: baseColor
              }
            },
            {
              name: '阈值',
              yAxis: data.alarmMax,label:{formatter:'阀值上限' ,color: '#ff4d4f',},
           
              lineStyle: {
                color: '#ff4d4f'
              }
            },
            {
              name: '阈值',
              yAxis: data.alarmMin,
              label: {formatter:'阀值下限' ,color: '#ff4d4f',},
              lineStyle: {
                color: '#ff4d4f'
              }
            }
          ]
        },
        data: data.yAxis
      },]
  };
  return option
}
// 多类型折线图
export const optionMoreline = (data) => {

  let lineY = [], legendName = []
  if (data.yAxis && data.yAxis.length) {
    data.yAxis.forEach(item => {

      var info = {
        name: item.name,
        type: 'line',
        smooth: false,
        connectNulls: true,
        // areaStyle: {
        //     normal: {

        //         shadowColor: 'rgba(0, 0, 0, 0.1)',
        //         shadowBlur: 10
        //     }
        // },
        symbol: 'circle',
        symbolSize: 5,
        data: item.yAxis
      }
      legendName.push(item.name)
      lineY.push(info)
    })
  }
  let option = {
    backgroundColor: '#15232D',
    legend: {
      top: '0%',
      right: '8%',
      data: legendName,
      textStyle: {
        fontSize: 14,
        color: 'F1F1F3'
      },
    },
    toolbox: {
      top: '0%',
      right: '4%',
      feature: {
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: 10,
      show: true,
      formatter: (params) => {
        if (!params || !params.length) return
        let result = params[0].name + "<br>"
        params.map((param, index) => {
          result += "&nbsp;&nbsp;" + param.marker + " " + param.seriesName + ":&nbsp;&nbsp;&nbsp;" + param.value + data.paramUnit + "<br>";

        })
        return result
      },
    },
    grid: {
      top: '34%',
      left: '4%',
      right: '4%',
      bottom: '6%',
      containLabel: true
    },
    xAxis: {
      show: true,
      type: 'category',
      boundaryGap: false,
      data: data.xAxis,
      axisLabel: {
        textStyle: {
          color: 'rgb(0,253,255,0.6)'
        },

      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgb(0,253,255,0.2)'
        }
      }
    },
    yAxis: {
      show: true,

      type: 'value',
      axisLabel: {
        // formatter: '{value}' + data.paramUnit,
        textStyle: {
          color: 'rgb(0,253,255,0.6)'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgb(23,255,243,0.1)'
        }
      },

      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgb(0,253,255,0.2)'
        }
      }
    },
    series: lineY
  }
  return option
}
// 动物分析饼图
export const optionAnimalPie = data => {
  let legendData = [], totalNub = 0, seriesData = []
  let colorList = ['#73DDFF', '#73ACFF', '#FDD56A', '#FDB36A', '#FD866A', '#9E87FF', '#58D5FF'];
  if (data && data.length) {
    data.forEach(item => {
      legendData.push(item.baseName)
      totalNub += item.liveStockNum
      seriesData.push({
        name: item.baseName,
        value: item.liveStockNum
      })
    })
  } else return

  let option = {

    title: [
      {
        text: '总计',
        x: '50%',
        y: '43%',
        textAlign: 'center',
        textStyle: {
          fontSize: '16',
          fontWeight: '500',
          color: '#fff',
          textAlign: 'center',
        },
      }, {
        text: totalNub + '头',
        left: '50%',
        top: '51%',
        textAlign: 'center',
        textStyle: {
          fontSize: '18',
          fontWeight: '500',
          color: store ?.state.themeInfo.baseColor || '#00C395',
          textAlign: 'center',
        },
      }
    ],
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: 5,
      formatter: function (parms) {
        var str = parms.marker + "" + parms.data.name + "</br>" +
          "数量：" + parms.data.value + "头</br>" +
          "占比：" + parms.percent + "%";
        return str;
      }
    },
    series: [{
      type: 'pie',
      z: 3,
      center: ['50%', '50%'],
      radius: ['25%', '45%'],
      clockwise: true,
      avoidLabelOverlap: true,
      hoverOffset: 15,
      itemStyle: {
        normal: {
          color: function (params) {
            return colorList[params.dataIndex]
          }
        }
      },
      label: {
        show: true,
        position: 'outside',
        formatter: '{a|{b}：{d}%}\n{hr|}',
        color: '#fff',
        rich: {
          hr: {
            backgroundColor: 't',
            borderRadius: 3,
            width: 3,
            height: 3,
            padding: [3, 3, 0, -12]
          },
          a: {
            padding: [-30, 15, -20, 15]
          }
        }
      },
      labelLine: {
        normal: {
          length: 20,
          length2: 30,
          lineStyle: {
            width: 1
          }
        }
      },
      data: seriesData
    }, {
      name: '第一层环',
      type: 'pie',
      z: 2,
      tooltip: {
        show: false
      },
      center: ['50%', '50%'],
      radius: ['45%', '58%'],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          // shadowBlur: 40,
          // shadowColor: 'rgba(0, 255, 255,.3)',
          color: 'rgba(23,38,48,.8)',
        },
        emphasis: {
          color: 'rgba(23,38,48,.8)',
        }
      },
      label: {
        show: false
      },
      data: [100]
    }, {
      name: '第二层环',
      type: 'pie',
      z: 1,
      tooltip: {
        show: false
      },
      center: ['50%', '50%'],
      radius: ['58%', '76%'],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          // shadowBlur: 40,
          // shadowColor: 'rgba(0, 255, 255,.3)',
          color: 'rgba(28,42,52,.6)',
        },
        emphasis: {
          color: 'rgba(28,42,52,.6)',
        }
      },
      label: {
        show: false
      },
      data: [100]
    }]
  }
  return option
}
// 动物分析线图
export const optionAnimalLine = data => {
  let categoryData = [];
  let chartdata = [];
  if (data && data.length) {
    data.forEach(item => {
      categoryData.push(item.baseName)
      let nub = 0
      if (item.animalBreedDeaths && item.animalBreedDeaths.length) {
        item.animalBreedDeaths.forEach(val => nub += val.deathNum)
      }
      chartdata.push(nub)
    })

  } else return


  let option = {

    textStyle: {
      color: '#c0c3cd',
      fontSize: 14,
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {
          backgroundColor: '#031245',
        },
        restore: {},
      },
      iconStyle: {
        borderColor: '#c0c3cd',
      },
    },
    legend: {
      top: 10,
      itemWidth: 8,
      itemHeight: 8,
      icon: 'circle',
      left: 'center',
      padding: 0,
      textStyle: {
        color: '#c0c3cd',
        fontSize: 14,
        padding: [2, 0, 0, 0],
      },
    },
    color: ['#00D7E9', 'rgba(0, 215, 233, 0.9)'],
    grid: {
      containLabel: true,
      left: 40,
      right: 20,
      bottom: 40,
      top: 40,
    },
    xAxis: {
      nameTextStyle: {
        color: '#c0c3cd',
        padding: [0, 0, -10, 0],
        fontSize: 14,
      },
      axisLabel: {
        color: '#c0c3cd',
        fontSize: 14,
        interval: 0,
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#384267',
          width: 1,
        },
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#335971',
        },
        show: true,
      },
      data: categoryData,
      type: 'category',
    },
    yAxis: {
      nameTextStyle: {
        color: '#c0c3cd',
        padding: [0, 0, -10, 0],
        fontSize: 14,
      },
      axisLabel: {
        color: '#c0c3cd',
        fontSize: 14,
      },
      axisTick: {
        lineStyle: {
          color: '#668092',
          width: 1,
        },
        show: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#335971',
          // "type": "dashed"
        },
      },
      axisLine: {
        lineStyle: {
          color: '#668092',
          width: 1,
          // "type": "dashed"
        },
        show: true,
      },
      name: '',
    },
    series: [
      {
        data: chartdata,
        type: 'bar',
        barMaxWidth: 'auto',
        barWidth: 20,
        itemStyle: {
          color: {
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            type: 'linear',
            colorStops: [
              {
                offset: 0,
                color: '#00D7E9',
              },
              {
                offset: 1,
                color: 'rgba(0, 167, 233,0.3)',
              },
            ],
          },
        },
        label: {
          show: true,
          position: 'top',
          distance: 10,
          color: '#fff',
        },
      },
      {
        data: [1, 1, 1, 1, 1, 1],
        type: 'pictorialBar',
        barMaxWidth: '20',
        symbol: 'diamond',
        symbolOffset: [0, '50%'],
        symbolSize: [20, 10],
      },
      {
        data: chartdata,
        type: 'pictorialBar',
        barMaxWidth: '20',

        symbolPosition: 'end',
        symbol: 'diamond',
        symbolOffset: [0, '-50%'],
        symbolSize: [20, 12],
        zlevel: 2,
      },
    ],
    tooltip: {
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: 5,
      show: true,
      formatter: '{b}:{c}',
    },

  }
  return option
}
// 柱状折线混图
export const optionBarLine = (echarts, dataInfo, type) => {

  let data1 = null, data2 = null, data3 = null;
  let xData = [], legendData = []
  if (dataInfo) {
    let { xAxis, charts } = dataInfo
    xData = xAxis
    charts.map(item => {
      let name = `${item.name}(${item.unit})`
      item.name = name
      switch (name) {
        case `杀虫数量(${item.unit})`:
          data1 = item
          break;
        case `温度(${item.unit})`:
          data2 = item
          break;
        case `湿度(${item.unit})`:
          data3 = item
          break;
      }
      legendData.push(name)

    })
  }
  let option = {
    // title:{
    //     text: '环境参数',
    //     x: '2%',
    //     y:'4%',
    //     textStyle:{
    //         fontSize: '16px',
    //         color: '#fff'
    //     }
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: [20, 20, 20, 20],
    },
    grid: {
      borderWidth: 0,
      top: '28%',
      left: '6%',
      bottom: '12%',
      right: '4%',
      textStyle: {
        color: '#fff',
      },
    },
    legend: {
      top: '5%',
      right: '4%',
      data: legendData,
      textStyle: {
        color: "#ffffff"
      }
    },
    xAxis: [
      {
        type: 'category',
        triggerEvent: true,
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.5)',
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#535766',
            type: 'dashed',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: '#999',
          formatter: function (params) {
            let arrParams = params.split(' ')
            return type ? arrParams[0] : arrParams[0] + '\n' + arrParams[1]
          }
        },
        data: xData,

      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          show: true,
          color: '#999',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
      },
    ],
    series: [
      {
        name: data1 ? data1.name : '电击次数',
        type: 'bar',
        // "stack": "总量",
        barWidth: 15,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: "#40E0FD"
            },
            {
              offset: 1,
              color: "#28353F"
            }
            ])
          }
        },
        data: data1 ? data1.yAxis : [],
      },
      {
        name: data2 ? data2.name : '环境温度',
        type: 'line',
        symbol: 'circle',
        itemStyle: {
          //折线拐点标志的样式
          color: store ?.state.themeInfo.baseColor || '#00C395'
        },
        lineStyle: {
          color: store ?.state.themeInfo.baseColor || '#00C395'
        },

        areaStyle: {
          color: 'rgba(0,195,149,0.2)',
        },
        data: data2 ? data2.yAxis : [],
      },
      {
        name: data3 ? data3.name : '环境湿度',
        type: 'line',
        symbol: 'circle',
        itemStyle: {
          //折线拐点标志的样式
          color: "#ffa800"
        },
        lineStyle: {
          color: "#ffa800"
        },

        areaStyle: {
          color: 'rgba(255,168,0,0.2)',
        },
        data: data3 ? data3.yAxis : [],
      },
    ],
  }
  return option
}
// 两柱一线混图-生长曲线
export const optionGrowBarLine = (dataInfo) => {
  let data1 = null, data2 = null, data3 = null;
  let xData = [], legendData = []
  if (dataInfo) {
    let { xAxis, charts } = dataInfo
    xData = xAxis
    charts.map(item => {
      // let name = `${item.name}(${item.unit})`
      // item.name = name
      switch (item.name) {
        case '身高':
          data1 = item
          break;
        case '体长':
          data2 = item
          break;
        case '体重':
          data3 = item
          break;
      }
      legendData.push(item.name)

    })
  }
  let option = {
    // title:{
    //     text: '环境参数',
    //     x: '2%',
    //     y:'4%',
    //     textStyle:{
    //         fontSize: '16px',
    //         color: '#fff'
    //     }
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: [20, 20, 20, 20],
    },
    grid: {
      borderWidth: 0,
      top: '38%',
      left: '15%',
      bottom: '15%',
      right: '10%',
      textStyle: {
        color: '#fff',
      },
    },
    legend: {
      top: '5%',
      right: '0%',
      data: legendData,
      itemHeight: 8,
      itemWidth: 12,
      textStyle: {
        color: "#ffffff",
        fontSize: 12
      }
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.5)',
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#535766',
            type: 'dashed',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: '#fff',
          formatter: function (params) {
            let arrParams = params.split(' ')
            return arrParams[0]
            // return type ? arrParams[0] : arrParams[0] + '\n' + arrParams[1]
          }
        },
        data: xData,

      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'kg',
        // nameLocation: 'start',
        nameTextStyle: {
          color: '#fff',
          align: 'right'
        },
        axisLabel: {
          show: false,
          color: '#999',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
        axisLabel: {
          formatter: '{value}',
          color: '#fff',
        }
      },
      {
        type: 'value',
        nameLocation: 'end',
        name: 'cm',
        // inverse: true,
        nameTextStyle: {
          color: '#fff',
          align: 'left'
        },
        min: 0,
        axisLabel: {
          show: true,
          color: '#999',
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
        axisLabel: {
          formatter: '{value}',
          color: '#fff',
        }
      },
      // {
      //   type: 'value',
      //   name: '杀虫数量',
      //   // nameLocation: 'start',
      //   nameTextStyle: {
      //     color: '#999',
      //     align: 'left'
      //   },
      //   position: 'right',
      //   offset: 45,
      //   min: 0,
      //   axisLabel: {
      //     show: true,
      //     color: '#999',
      //   },
      //   splitLine: {
      //     show: true,
      //     lineStyle: {
      //       color: '#91939d',
      //       type: 'dashed',
      //     },
      //   },
      //   axisLabel: {
      //     formatter: '{value} 头'
      //   }
      // }
    ],
    series: [
      {
        name: data1 ? data1.name : '身高',
        type: 'bar',
        // "stack": "总量",
        // barWidth: 8,
        barMaxWidth:8,
        itemStyle: {
          normal: {
            color: store ?.state.themeInfo.baseColor,
            barBorderRadius: [4,4,0,0]
          }
        },
        yAxisIndex: 1,
        data: data1 ? data1.yAxis : [],
      },
      {
        name: data2 ? data2.name : '体长',
        type: 'bar',
        // "stack": "总量",
        // barWidth: 8,
        barMaxWidth:8,
        itemStyle: {
          normal: {
            color: '#32A1FF',
            barBorderRadius: [4,4,0,0]
          }
        },
        yAxisIndex: 1,
        data: data2 ? data2.yAxis : [],
      },
      {
        name: data3 ? data3.name : '体重',
        type: 'line',
        symbol: 'circle',
        itemStyle: {
          //折线拐点标志的样式
          color: "#ffa800"
        },
        lineStyle: {
          color: "#ffa800"
        },

        areaStyle: {
          color: 'rgba(255,168,0,0.2)',
        },
        data: data3 ? data3.yAxis : [],
      },
    ],
  }
  return option
}
// 饮水监测折线
export const optionWaterLine = (data) => {
  let maxNub = data.region[1]
  let minNub = data.region[0]
  const visualMap = [
    {
      type: "piecewise",
      show: false,
      dimension: 1,
      seriesIndex: 0,
      pieces: [
          {
              gt: maxNub,
              color: '#FFA800', //偏高
          },
          {
              gte: minNub,
              lte: maxNub,
              color: '#32A1FF', //正常
          },
          {
              lt: minNub,
              color: '#FFf',//偏低
          },
      ],
      outOfRange: {
          // 在选中范围外 的视觉元素，这里设置在正常范围内的图形颜色
          color: '#32A1FF',
      },
    }
  ]
 
  let option = {

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      // borderColor: '#00C395',
      borderWidth: 1,
      padding:[0,5,0,5],
      formatter: function (arr) {
  
        let param = arr[0]
        let color = '#32A1FF'
        let text = '正常'
        if(param.value > maxNub){
           text= '偏高'
           color= '#FFA800'
        }else if(param.value < minNub){
          text= '偏低'
          color= '#FFf'
        }
        return `<div style="padding:0 5px;color:${color};">${text}</div><div style="padding:0 5px;color:${color};">${param.value}次</div>`
        // let param = arr[0]
        // return param.marker + " " + param.name + "<br>"
        //   + "&nbsp;&nbsp;&nbsp;&nbsp;" + param.seriesName + ":&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;" + param.value + "次<br>";
      }

    },
     //超出标准线颜色
    visualMap,
    grid: {
      top: '26%',
      left: '10%',
      right: '10%',
      bottom: '18%'
    },
    legend: {
      top: '5%',
      right: '0%',
      itemHeight: 8,
      itemWidth: 12,
      textStyle: {
        color: "#ffffff",
        fontSize: 12
      }
    },
    xAxis: {
      //  type: 'category',
      data: data.xAxis,
      axisLine: {
        show: true,
        lineStyle: {
          color: '#999',
          type: 'dashed',
        },
      },
      axisTick:{
        show: false,  
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff', //X轴文字颜色
          fontSize: 10
        },
        formatter: function (params) {
          var newParamsName = ''; // 最终拼接成的字符串
          params=  params.split(' ')[0]
          var paramsNameNumber = params.length; // 实际标签的个数
          var provideNumber = params.indexOf('-'); // 每行能显示的字的个数
          var str1 = params.substring(0, provideNumber);
          var str2 = params.substring(provideNumber+1, paramsNameNumber);
          newParamsName = str1 + '\n' + str2;

          return newParamsName;
        }
      },
    },
    yAxis: {
      name: '次',
      nameTextStyle: {
        color: "#fff",
        fontSize: 12,
      },
      axisLine: {
        show:true,
        lineStyle: {
          color: '#999',
          type: 'dashed',
        },
      },
      splitLine: {
        show: false
      },
      type: 'value',
      axisLabel: {
        color: "#fff",
        formatter: '{value}'
      }
    },
    series: [
      {
        name: '饮水次数',
        type: 'line',
        color: ['#32A1FF'],
        markLine: {
          symbol: 'none',
          silent: true,
          lineStyle: {
              color: "#FFA800",
          },
          data: [
            {
              type: 'average', 
              label: {
                formatter: '',
              },
              yAxis: data.region[0]
            },
            {
              type: 'average', 
              label: {
                formatter: '',
              },
              yAxis: data.region[1]
            },
          ]
        },
        data: data.yAxis
      },]
  };
  return option
}
// 用量趋势图折线
export const dosageLine = (data) => {
  let option = {

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: 5,
      formatter: function (arr) {
        let param = arr[0]
        return param.marker + " " + param.name + "<br>"
          + "&nbsp;&nbsp;&nbsp;&nbsp;" + param.seriesName + ":&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;" + param.value + "m²/h<br>";
      }

    },
    grid: {
      top: '26%',
      left: '6%',
      right: '6%',
      bottom: '20%'
    },
    xAxis: {
      //  type: 'category',
      data: data.xAxis,
    
      axisLabel: {
        show: true,
        textStyle: {
          color: '#999', //X轴文字颜色
          fontSize: 10,
         
        },
        margin: 0,
        formatter: function (params) {
          var newParamsName = ''; // 最终拼接成的字符串
          var paramsNameNumber = params.length; // 实际标签的个数
          var provideNumber = params.indexOf(' '); // 每行能显示的字的个数
          var str1 = params.substring(0, provideNumber);
          var str2 = params.substring(provideNumber, paramsNameNumber);
          newParamsName = str1 + '\n' + str2;

          return newParamsName;
        }
      },
    },
    yAxis: {
      name: 'm²/h',
      nameGap: 5,
      nameTextStyle: {
        color:'#999',
        fontSize: 12,
      },
      type: 'value',
      axisLabel: {
        formatter: '{value}'
      },
      // axisTick: {
      //   lineStyle: {
      //     color: '#999',
      //     width: 1,
      //   },
      //   show: true,
      // },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#999',
          // "type": "dashed"
        },
      },
      axisLine: {
        lineStyle: {
          color: '#999',
          width: 1,
          // "type": "dashed"
        },
        show: true,
      },
    },
    series: [
      {
        name: '用量趋势',
        type: 'line',
        color: [store ?.state.themeInfo.baseColor || '#00C395'],
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(0,196,151,1)'
            }, {
              offset: 0.8,
              color: 'rgba(0,196,151,0.1)'
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
          }
        },
        // markPoint: {
        //   data: [
        //     {
        //       type: 'max', name: '最大值', label: {
        //         formatter: '{c}' + data.paramUnit,
        //         color: "#fff"
        //       }
        //     },
        //     {
        //       type: 'min', name: '最小值', label: {
        //         formatter: '{c}' + data.paramUnit,
        //         color: "#fff"
        //       }
        //     }
        //   ]
        // },
        // markLine: {
        //   data: [
        //     {
        //       type: 'average', name: '平均值', label: {
        //         formatter: '{c}' + data.paramUnit,
        //       }
        //     }
        //   ]
        // },
        data: data.yAxis
      },]
  };
  return option
}
// 多轴柱状折线混图
export const optionMoreAxisBarLine = (echarts, dataInfo, type) => {

  let data1 = null, data2 = null, data3 = null;
  let xData = [], legendData = []
  if (dataInfo) {
    let { xAxis, charts } = dataInfo
    xData = xAxis
    charts.map(item => {
      let name = `${item.name}(${item.unit})`
      item.name = name
      switch (name) {
        case `杀虫数量(${item.unit})`:
          data1 = item
          break;
        case `温度(${item.unit})`:
          data2 = item
          break;
        case `湿度(${item.unit})`:
          data3 = item
          break;
      }
      legendData.push(name)

    })
  }
  let option = {
    // title:{
    //     text: '环境参数',
    //     x: '2%',
    //     y:'4%',
    //     textStyle:{
    //         fontSize: '16px',
    //         color: '#fff'
    //     }
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: [20, 20, 20, 20],
    },
    grid: {
      borderWidth: 0,
      top: '28%',
      left: '6%',
      bottom: '12%',
      right: '10%',
      textStyle: {
        color: '#fff',
      },
    },
    legend: {
      top: '5%',
      right: '4%',
      data: legendData,
      textStyle: {
        color: "#ffffff"
      }
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.5)',
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#535766',
            type: 'dashed',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: '#999',
          formatter: function (params) {
            let arrParams = params.split(' ')
            return type ? arrParams[0] : arrParams[0] + '\n' + arrParams[1]
          }
        },
        data: xData,

      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '湿度',
        // nameLocation: 'start',
        nameTextStyle: {
          color: '#999',
          align: 'right'
        },
        axisLabel: {
          show: true,
          color: '#999',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
        axisLabel: {
          formatter: '{value} %RH'
        }
      },
      {
        type: 'value',
        nameLocation: 'end',
        name: '温度',
        // inverse: true,
        nameTextStyle: {
          color: '#999',
          align: 'left'
        },
        min: 0,
        axisLabel: {
          show: true,
          color: '#999',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
        axisLabel: {
          formatter: '{value} ℃'
        }
      },
      {
        type: 'value',
        name: '杀虫数量',
        // nameLocation: 'start',
        nameTextStyle: {
          color: '#999',
          align: 'left'
        },
        position: 'right',
        offset: 45,
        min: 0,
        axisLabel: {
          show: true,
          color: '#999',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
        axisLabel: {
          formatter: '{value} 头'
        }
      }
    ],
    series: [
      {
        name: data1 ? data1.name : '电击次数',
        type: 'bar',
        // "stack": "总量",
        barWidth: 15,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: "#40E0FD"
            },
            {
              offset: 1,
              color: "#28353F"
            }
            ])
          }
        },
        yAxisIndex: 2,
        data: data1 ? data1.yAxis : [],
      },
      {
        name: data2 ? data2.name : '环境温度',
        type: 'line',
        symbol: 'circle',
        yAxisIndex: 1,
        itemStyle: {
          //折线拐点标志的样式
          color: store ?.state.themeInfo.baseColor || '#00C395'
        },
        lineStyle: {
          color: store ?.state.themeInfo.baseColor || '#00C395'
        },

        areaStyle: {
          color: 'rgba(0,195,149,0.2)',
        },
        data: data2 ? data2.yAxis : [],
      },
      {
        name: data3 ? data3.name : '环境湿度',
        type: 'line',
        symbol: 'circle',
        itemStyle: {
          //折线拐点标志的样式
          color: "#ffa800"
        },
        lineStyle: {
          color: "#ffa800"
        },

        areaStyle: {
          color: 'rgba(255,168,0,0.2)',
        },
        data: data3 ? data3.yAxis : [],
      },
    ],
  }
  return option
}
// 风向折线图
export const optionWindLine = data => {
  let yAxis = []
  let jtIcon = require('../assets/imgs/monitoring/default/jt.png')
  data.yAxis.map(val => {
    yAxis.push({
      value: val,
      symbol: 'image://' + jtIcon, // 风向符号
      symbolSize: 15, // 大小
      symbolOffset: [0, 0], // 偏移
      symbolRotate: val, // 角度
      itemStyle: {
        color: store ?.state.themeInfo.baseColor || '#00C395',
        size: 20,
      },
    })
  })
  let paramValToWindText = paramVal => {

    let valArr = [
      {
        max: 112.5,
        min: 67.5,
        name: '北风'
      },
      {
        max: 157.5,
        min: 112.5,
        name: '西北风'
      },
      {
        max: 202.5,
        min: 157.5,
        name: '西风'
      },
      {
        max: 247.5,
        min: 202.5,
        name: '西南风'
      },
      {
        max: 292.5,
        min: 247.5,
        name: '南风'
      },
      {
        max: 337.5,
        min: 292.5,
        name: '东南风'
      },
      {
        max: 22.5,
        min: 337.5,
        name: '东风'
      },
      {
        max: 67.5,
        min: 22.5,
        name: '东北风'
      }
    ]
    let val = parseInt(paramVal)
    let resultName = '无风'
    valArr.some(item => {

      if (val > 337.5 || val <= 22.5) {
        resultName = '东风'
        return true
      }
      if (val > item.min && val <= item.max) {
        resultName = item.name
        return true
      }
    })

    return resultName

  }

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: 5,
      formatter: function (arr) {
        let param = arr[0]
        let windText = paramValToWindText(param.value)
        return param.marker + " " + param.name + "<br>"
          + "&nbsp;&nbsp;&nbsp;&nbsp;" + param.seriesName + ":&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;" + windText + "<br>";
      }

    },
    grid: {
      top: '26%',
      left: '6%',
      right: '6%',
      bottom: '18%'
    },
    xAxis: {
      //  type: 'category',
      data: data.xAxis,
      axisLabel: {
        show: true,
        textStyle: {
          color: '#999', //X轴文字颜色
          fontSize: 10
        },
        formatter: function (params) {
          var newParamsName = ''; // 最终拼接成的字符串
          var paramsNameNumber = params.length; // 实际标签的个数
          var provideNumber = params.indexOf(' '); // 每行能显示的字的个数
          var str1 = params.substring(0, provideNumber);
          var str2 = params.substring(provideNumber, paramsNameNumber);
          newParamsName = str1 + '\n' + str2;

          return newParamsName;
        }
      },
    },
    yAxis: {
      name: data.paramUnit,
      nameTextStyle: {
        color: store ?.state.themeInfo.baseColor || '#00C395',
        fontSize: 14,
      },
      type: 'value',
      axisLabel: {
        formatter: '{value}'
      }
    },
    series: [
      {
        name: data.paramName,
        type: 'line',
        color: [store ?.state.themeInfo.baseColor || '#00C395'],
        // markLine: {
        //         data: [
        //             {type: 'average', name: '平均值',label: {
        //                 formatter: '{c}' + data.paramUnit,
        //             }}
        //         ]
        // },
        data: yAxis
      },
    ]
  }
  return option
}
// 动物体温柱状对比值
export const optionAnimalTemp = (data) => {
  let list = data.traAnimalInfoTTmpAnas
  let maxVal = list[0].tempMax
  let minVal = list[0].tempMin || 0
  let xAxisList = []
  let seriesList = []
  list.map((item) => {
    xAxisList.push(item.name || '')
    seriesList.push({
      value: item.temperature,
      itemStyle: {
        color: "#3cefff"
      }
    })
  })
  let option = {
    backgroundColor: '#15232D',
    grid: {
      top: '24%',
      left: '4%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
    dataZoom: [
      {
        type: "inside", //slider表示有滑动块的，inside表示内置的
        show: false,
        xAxisIndex: 0,
        start: 0,
        end: 100,
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: [10, 10, 10, 10],
      formatter: (params) => {
        return params[2].name + '：' + `${params[2].value ? params[2].value : '暂无数据'}` + `${params[2].value ? '℃' : ''}`
      }
    },
    xAxis: [{
      type: "category",
      data: xAxisList,
      axisTick: {
        alignWithLabel: true
      },
      nameTextStyle: {
        color: "#999"
      },
      axisLine: {
        lineStyle: {
          color: "rgb(0,253,255,0.2)"
        }
      },
      axisLabel: {
        textStyle: {
          color: "#999"
        }
      }
    }],
    yAxis: [{
      type: "value",
      axisLabel: {
        textStyle: {
          color: "#999"
        },
        formatter: "{value}℃"
      },
      splitLine: {
        lineStyle: {
          color: "rgb(0,253,255,0.2)"
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgb(0,253,255,0.2)"
        }
      }
    }],
    series: [
      {
        name: "",
        type: 'pictorialBar',
        symbolSize: [20, 10],
        symbolOffset: [0, -5],
        symbolPosition: 'end',
        z: 12,
        //"barWidth": "20",
        label: {
          normal: {
            show: true,
            position: "top",
            formatter: (params) => {
              let val = Number(params.value)
              if (val > minVal && val < maxVal) {
                return val + '℃' + '( 正常 )'
              } else {
                return val + '℃' + '( 异常 )'
              }
            }
          }
        },
        data: seriesList
      },
      {
        name: '',
        type: 'pictorialBar',
        symbolSize: [20, 10],
        symbolOffset: [0, 5],
        // "barWidth": "20",
        z: 12,
        data: seriesList
      },
      {
        type: 'bar',
        itemStyle: {
          normal: {
            //color: '#14b1eb',
            opacity: .7
          }
        },
        //silent: true,
        barWidth: "20",
        //barGap: '-100%', // Make series be overlap
        data: seriesList,
        markLine: {
          symbol: 'none',
          label: {
            position: "middle",
            formatter: '{b}'
          },
          data: [{
            name: "最高值" + maxVal + '℃',
            yAxis: maxVal,//res.targetTwo,
            lineStyle: {
              color: "#ffa800"
            },
          },
          {
            name: "最低值" + minVal + '℃',
            yAxis: minVal,//res.targetTwo,
            lineStyle: {
              color: "#324cea"
            },
          }]
        }
      }
    ]
  }
  return option
}
// 动物步数饼状图
export const optionAnimalSteps = (data) => {

  let seriesList = []

  if (data.normal > 0) {
    seriesList.push({
      value: data.normal,
      name: '正常'
    })
  }
  if (data.sameAs > 0) {
    seriesList.push({
      value: data.sameAs,
      name: '一般'
    })
  }
  if (data.unusual > 0) {
    seriesList.push({
      value: data.unusual,
      name: '异常'
    })
  }

  var color = ['#57d5a9', '#ebb563', '#f56c6c']

  let option = {
    color: color,
    backgroundColor: '#15232D',
    grid: {
      top: '24%',
      left: '4%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
    title: {
      text: '步数',
      left: 'center',
      top: '46%',
      textStyle: {
        fontSize: 18,
        color: '#fff',
        fontWeight: 'normal'
      }
    },
    tooltip: {
      trigger: 'item',
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      formatter: (params) => {
        return params.name + '：' + params.value + ' %'
      }
    },
    legend: {
      orient: 'vertical',
      right: 10,
      top: 10,
      textStyle: {
        color: "#fff",
        fontSize: 12
      }
    },
    series: [{
      type: 'pie',
      // roseType: 'area',
      radius: ['60%', '75%'],
      data: seriesList,
      label: {
        normal: {
          // formatter: '{font|{d}%}',
          formatter: (params) => {
            return params.data.value + '%' + '( ' + params.data.name + ' )'
          },
          rich: {
            font: {
              fontSize: 14,
              padding: [5, 0],
              color: '#fff'
            },
            hr: {
              height: 0,
              borderWidth: 1,
              width: '100%',
              borderColor: '#fff'
            }
          }
        },
      },
      labelLine: {
        lineStyle: {
          color: '#fff'
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0,0,0,0.5)'
        }
      }
    }]
  };
  return option
}
// 动物生理三柱状图
export const optionAnimalFeature = (data) => {
  let list = data.data
  let xAxisList = []
  let heightList = []
  let lengthList = []
  let weightList = []
  list.map((item => {
    xAxisList.push(item.name)
    heightList.push(item.height)
    lengthList.push(item.length)
    weightList.push(item.weight)
  }))

  let option = {
    backgroundColor: '#15232D',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: store ?.state.themeInfo.baseColor || '#00C395',
      borderWidth: 2,
      padding: [10, 10, 10, 10],
    },
    dataZoom: [
      {
        type: "inside", //slider表示有滑动块的，inside表示内置的
        show: false,
        xAxisIndex: 0,
        start: 0,
        end: 100,
      },
    ],
    legend: {
      data: ['身高CM', '体重KG', '体长CM'],
      align: 'right',
      right: 10,
      textStyle: {
        color: "#fff"
      },
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 35
    },
    grid: {
      top: '24%',
      left: '4%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      data: xAxisList,
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgb(0,253,255,0.2)",
          width: 1,
          type: "solid"
        }
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#999",
        }
      },
    }],
    yAxis: [{
      type: 'value',
      axisLabel: {
        formatter: '{value}'
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#999",
          width: 1,
          type: "solid"
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgb(0,253,255,0.2)",
        }
      }
    }],
    series: [{
      name: '身高CM',
      type: 'bar',
      data: heightList,
      barWidth: 10, //柱子宽度
      barGap: 1, //柱子之间间距
      itemStyle: {
        normal: {
          color: store ?.state.themeInfo.baseColor || '#00C395',//
          opacity: 1,
        }
      }
    }, {
      name: '体重KG',
      type: 'bar',
      data: weightList,
      barWidth: 10,
      barGap: 1,
      itemStyle: {
        normal: {
          color: '#008cff',
          opacity: 1,
        }
      }
    }, {
      name: '体长CM',
      type: 'bar',
      data: lengthList,
      barWidth: 10,
      barGap: 1,
      itemStyle: {
        normal: {
          color: '#ebb563',
          opacity: 1,
        }
      }
    }]
  };
  return option
}
// 酸碱度平均值
export const optionPhAverage = (nub,typeName) => {
   const typeColor = nub > 7 ? '#3B9FEF' : '#0ABEA6'
   return {
    tooltip: {
      trigger: "item",
    },
    angleAxis: {
      show: false,
      max: (100 * 360) / 270,
      type: "value",
      startAngle: 225,
      splitLine: {
        show: false,
      },
    },
    //圆环宽度
    barMaxWidth: 8,
    radiusAxis: {
      show: false,
      type: "category",
      z: 10,
    },
    polar: {
      //圆环大小
      radius: "160%",
    },
    series: [
      {
        type: "bar",
        data: [
          {
            value: 100,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                    offset: 0,
                    color: store ?.state.themeInfo.baseColor,
                },
                {
                    offset: 1,
                    color: '#4699FF',
                },
            ]),
              // shadowColor: "rgba(35, 176, 176, 0.5)",
              // shadowBlur: 10,
            },
          },
        ],
        barGap: "-100%",
        coordinateSystem: "polar",
        roundCap: true,
        z: 2,
        animationDuration: 2000,
      },
      // {
      //   //底层圆环
      //   type: "bar",
      //   data: [
      //     {
      //       value: 100,
      //       itemStyle: {
      //         color: "#EBEDF5"
      //       },
      //     },
      //   ],
      //   barGap: "-100%",
      //   coordinateSystem: "polar",
      //   roundCap: true,
      //   z: 1,
      //   animation: false,
      // },
      {
        name: "",
        type: "gauge",
        //startAngle: 180,
        //endAngle: 0,
        min: 0.0,
        max: 14.0,
        splitNumber: 2,
        radius: "70%", //图表尺寸
        center: ["50%", "50%"],
        detail: {
          formatter: "{r0|"+ typeName +"}\n{r1|平均值}\n{r2|{value}}",
          color: "auto",
          rich: {
            r0: {
              fontSize: 14,
              color: typeColor,
              verticalAlign: "bottom",
              padding: [0,0, -20, 0],
            },
            r1: {
              fontSize: 14,
              color: '#fff',
              verticalAlign: "bottom",
              padding: [0,0, -25, 0],
            },
            r2: {
              fontSize: 18,
              color: store ?.state.themeInfo.baseColor,
              verticalAlign: "bottom",
              padding: [0,0, -20, 0],
            },
          },
        },
        // anchor: {
        //   show: true,
        //   showAbove: true,
        //   size: 5,
        //   itemStyle: {
        //     borderWidth: 2,
        //     borderColor: "#00C497",
        //   },
        // },
        pointer: {
          icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
          length: "75%",
          width: 5,
          offsetCenter: [0, "5%"],
        },
        progress: {
          roundCap: true,
          show: true,
          width: 0,
        },
        itemStyle: {
          color: typeColor,
        },
        data: [
          {
            value: nub,
            name: "",
          },
        ],
        axisLine: {
          show: true,
          lineStyle: {
            width: 0,
            shadowBlur: 0,
            color: [
              [0.2, "#91FFE6"],
              [0.4, "#59F9D4"],
              [0.5, store ?.state.themeInfo.baseColor],
              [0.6, "#67B7FF"],
              [1, "#4699FF"],
            ],
          },
        },
        axisTick: {
          show: true,
          lineStyle: {
            color: "auto",
            width: 2,
          },
          length: 10,
          splitNumber: 5,
        },
        splitLine: {
          show: true,
          length: 12,
          lineStyle: {
            color: "auto",
            width: 2,
          },
        },
        axisLabel: {
          distance: 5,
          color: "#9BA5BC",
          fontSize: 12,
        },
      },
      {
        type: "pie",
        labelLine: {
          show: false,
        },
        z: 1,
        radius: 8,
        data: [
          {
            value: 5,
            itemStyle: {
              color: "#EAEBF1",
            },
          },
        ],
      },
      // {
      //   type: "pie",
      //   labelLine: {
      //     show: false,
      //   },
      //   z: 6,
      //   radius: 3,
      //   data: [
      //     {
      //       value: 100,
      //       itemStyle: {
      //         color: "#fff",
      //       },
      //     },
      //   ],
      // },
    ],
   }
}
// 酸碱度汇总
export const optionPhSummary = (chartData) => {
    console.log(chartData)  
    const colorList = ['#4699FF', '#67B7FF', store ?.state.themeInfo.baseColor, '#59F9D4', '#91FFE6']
    const sum = chartData.reduce((per, cur) => per + cur.value, 0)
    const gap = (1 * sum) / 100
    const pieData1 = []
    const pieData2 = []
    const gapData = {
        name: '',
        value: gap,
        itemStyle: {
            color: 'transparent'
        }
    }
    for (let i = 0; i < chartData.length; i++) {
        // 第一圈数据
        pieData1.push({
            ...chartData[i],
            itemStyle: {
                borderRadius: 10
            }
        })
        // pieData1.push(gapData)

        // 第二圈数据
        pieData2.push({
            ...chartData[i],
            itemStyle: {
                color: colorList[i],
                opacity: 0.21
            }
        })
        // pieData2.push(gapData)
    }
   return {
    title: [
      {
          text: "汇总",
          textStyle: {
              color: "#ffffff",
              align: "center",
              fontSize: 24,
          },
          textAlign: "center",
          x: "49%",
          y: "38%",
      }
  ],
    tooltip: {
        show: true,
        backgroundColor: 'rgba(0, 0, 0,.8)',
        textStyle: {
            color: '#fff'
        },
        formatter: function (params) {        
          if (params.name !== '') {
            return `${params.name}: ${params.percent}%`;
          } else {
            return '';
          }
        }
    },
    legend: {
        show: true,
        left: 'center',
        bottom: '5%',
        align: 'left',
        itemGap: 18,
        // orient: 'vertical',
        // icon: 'rect',
        itemWidth: 13,
        itemHeight: 7,
        // symbolKeepAspect: false,
        textStyle: {
            color: '#D8DDE3',
            rich: {
                name: {
                    verticalAlign: 'right',
                    align: 'left',
                    width: 150,
                    fontSize: 14,
                    color: '#D8DDE3'
                },
                percent: {
                    padding: [0, 0, 0, 8],
                    color: '#D8DDE3'
                }
            },
            borderWidth: 53 // 间距的宽度
        },
        // formatter: name => {
        //     console.log(name)
        //     console.log(chartData)
        //     const item = chartData.find(i => {
        //         return i.name === name
        //     })
        //     const p = ((item.value / sum) * 100).toFixed(0)
        //     return '{name|' + name + '}' + '{percent|' + p + '}' + '个'
        // }
    },
    grid: {
        top: 35,
        right: 30,
        bottom: 20,
        left: 30
    },
    color: colorList,
    series: [
        {
            name: '',
            type: 'pie',
            roundCap: true,
            radius: ['66%', '70%'],
            center: ['50%', '45%'],
            label: {
                show: false
            },
            labelLine: {
                show: false
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'outside',
                  color: '#fff',
                  formatter: function (params) {
                  
                    if (params.name !== '') {
                      return params.percent + '%';
                    } else {
                      return '';
                    }
                  },
                },
                labelLine: {
                  length: 10,
                  length2: 20,
                  show: true,
                  color: '#00ffff',
                },
              },
            },
            data: pieData1
        },
        {
            type: 'pie',
            radius: ['66%', '54%'],
            center: ['50%', '45%'],
            gap: 1.71,
            label: {
                show: false
            },
            labelLine: {
                show: false
            },
            silent: true,
            data: pieData2
        },
        {
            type: 'pie',
            center: ['50%', '45%'],
            radius: [0, '45.6%'],
            label: {
                show: false
            },
            labelLine: {
                show: false
            },
            itemStyle: {
                color: 'rgba(75, 126, 203,.1)'
            },
            silent: true,
            data: [
                {
                    value: 100,
                    name: ''
                }
            ]
        }
    ]
   }
}